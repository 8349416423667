<template>
  <b-card>
    <title-name />
    <term-content />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import TitleName from '@/views/Admin/Defines/Proforma_terms/elements/Title.vue'
import TermContent from '@/views/Admin/Defines/Proforma_terms/elements/Content.vue'

export default {
  name: 'ProformaTermForm',
  components: {
    BCard,
    TitleName,
    TermContent,
  },
}
</script>
